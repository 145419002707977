import {MonorailTracker} from '../../common/analytics';
import {
  constructLink,
  getVariantsFromAttributeString,
} from '../payButton/utils';
import {Cart, PaymentOption} from '../payButton/types';
import {
  AvailableLoanType,
  InstallmentPlan,
  ModalType,
  ModalUserAction,
  VariantModalDetails,
  SamplePlan,
} from '../../types';

import {
  getDynamicPDPText,
  getFormattedSamplePlans,
  getDynamicPDPTemplateContents,
  getNavigationButtons,
} from './utils';

export class ShopifyInstallmentsSamplePlansModal extends HTMLElement {
  private _closeButtons?: NodeListOf<Element>;
  private _samplePlans: SamplePlan[];
  private _modalToken: string;
  private _continueToCheckoutButton: Element;
  private _permalink: string;
  private _monorailTracker: MonorailTracker;
  private _trackContinueToCheckout = true;

  constructor(
    modalToken: string,
    monorailTracker: MonorailTracker,
    installmentPlans: InstallmentPlan[],
    priceWithoutInterest: string,
    variantInfo?: VariantModalDetails,
    cart?: Cart,
  ) {
    super();
    const template = document.createElement('template');
    this._modalToken = modalToken;
    this._monorailTracker = monorailTracker;
    this._trackContinueToCheckout = variantInfo?.available ?? true;

    this._samplePlans = getFormattedSamplePlans(
      installmentPlans,
      priceWithoutInterest,
    );

    const {subTitle, legalCopy} = getDynamicPDPText(
      this._samplePlans.length,
      priceWithoutInterest,
    );

    template.innerHTML = getDynamicPDPTemplateContents(
      subTitle,
      legalCopy,
      this._samplePlans,
    );
    this.appendChild(template.content.cloneNode(true));

    const navigationButtons =
      this.getElementsByClassName('navigation-buttons')[0];

    const buttonText = getButtonText(variantInfo);

    navigationButtons.innerHTML = getNavigationButtons(
      window.location.origin,
      this._modalToken,
      buttonText,
      variantInfo,
      cart,
    );
    this._continueToCheckoutButton =
      navigationButtons.querySelector('shop-pay-button')!;

    // If no cart token is defined, it means that the modal was built for PDP
    this._permalink = cart
      ? cart.token
      : constructLink({
          storeUrl: window.location.origin,
          variants: variantInfo
            ? getVariantsFromAttributeString(variantInfo.idQuantityMapping)
            : [],
          paymentOption: PaymentOption.ShopPayInstallments,
          source: 'installments_modal',
          sourceToken: this._modalToken,
        });
  }

  handleClose = () => {
    this._monorailTracker.trackModalAction(
      this._modalToken,
      ModalUserAction.Close,
      this._permalink,
    );
    const event = new Event('shopify_modal_close');
    this.dispatchEvent(event);
  };

  handleEscKey = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      this.handleClose();
    }
  };

  handleContinueToCheckout = () => {
    if (!this._trackContinueToCheckout) return;

    this._monorailTracker.trackModalAction(
      this._modalToken,
      ModalUserAction.ContinueToCheckout,
      this._permalink,
    );
  };

  connectedCallback() {
    this._closeButtons = this.querySelectorAll('.btn__close');
    if (!this._closeButtons || this._closeButtons.length === 0) return;

    this._closeButtons.forEach((btn) =>
      btn.addEventListener('click', this.handleClose),
    );
    // Autofocus on the first element inside modal
    (this._closeButtons[0] as HTMLButtonElement).focus();

    if (this._continueToCheckoutButton) {
      this._continueToCheckoutButton.addEventListener(
        'click',
        this.handleContinueToCheckout,
      );
    }
    window.addEventListener('keydown', this.handleEscKey);
  }

  disconnectedCallback() {
    if (!this._closeButtons) return;

    this._closeButtons.forEach((btn) =>
      btn.removeEventListener('click', this.handleClose),
    );

    if (this._continueToCheckoutButton) {
      this._continueToCheckoutButton.removeEventListener(
        'click',
        this.handleContinueToCheckout,
      );
    }

    window.removeEventListener('keydown', this.handleEscKey);
  }

  getModalSamplePlans(): SamplePlan[] {
    return this._samplePlans;
  }

  getPermalink(): string | undefined {
    return this._permalink;
  }

  getModalToken(): string {
    return this._modalToken;
  }

  getModalHeight(): string {
    return '767px';
  }

  getModalType() {
    const hasZeroInterestLoan = this._samplePlans.some(
      (plan) => plan.apr === 0 && plan.loanType === AvailableLoanType.Interest,
    );

    if (hasZeroInterestLoan) {
      const onlyZeroInterestLoans = this._samplePlans.every(
        (plan) => plan.apr === 0,
      );
      return onlyZeroInterestLoans
        ? ModalType.ZeroInterestOnly
        : ModalType.ZeroInterest;
    }

    // At this point, we know that no zero interest loans are present
    const isAdaptiveRange =
      this._samplePlans.some(
        (plan) => plan.loanType === AvailableLoanType.SplitPay,
      ) &&
      this._samplePlans.some(
        (plan) => plan.loanType === AvailableLoanType.Interest,
      );

    return isAdaptiveRange ? ModalType.Adaptive : ModalType.InterestOnly;
  }
}

/**
 * @param {VariantModalDetails} variantInfo information on the variant
 * @returns {string} Text for the button
 */
function getButtonText(variantInfo?: VariantModalDetails): string {
  return variantInfo?.available === false
    ? 'Unavailable'
    : 'Continue to checkout';
}
